// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
// reportWebVitals();



// index.js or App.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BreadcrumbProvider } from './components/pages/main/BreadcrumbContext';

ReactDOM.render(
  <React.StrictMode>
    <BreadcrumbProvider>
      <App />
    </BreadcrumbProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
