import React, { useState } from "react";
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import { submitCareerApplication } from "../../utils/ApiService";
import swal from "sweetalert";

const Careers = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    job: "",
    mobile: "", // Changed from 'phone' to 'mobile'
    resume: null,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.mobile) errors.mobile = "Mobile number is required"; // Changed from 'phone' to 'mobile'
    if (!formData.job) errors.job = "Position is required";
    if (!formData.resume) errors.resume = "Resume is required";
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      await submitCareerApplication(data);
      handleClose(); // Close modal on success
      swal("Good job!", "submit successful!", "success");
      setFormData({
        name: "",
        email: "",
        job: "",
        mobile: "", // Reset 'mobile' field
        resume: null,
      });
      setFormErrors({});
    } catch (error) {
      console.error("Error submitting application:", error);
      swal("Oops!", "submit failed. Please try again later.", "error");
    }
  };

  return (
    <div>
      <Header />
      <Container
        style={{
          marginTop: "40px",
          marginBottom: "40px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        <Row className="justify-content-center">
          <Col xs={12} md={6} className="text-center">
            <Image src="./images/carrers.jpg" alt="Careers" fluid />
            <h1 style={{ marginTop: "20px" }}>Apply Now</h1>
            <p>
              We are always looking for creative, talented self-starters to join
              the Saaskin family.
            </p>
            <p>Check out our open positions and fill out an application.</p>
            <Button
              variant="success"
              style={{ padding: "10px 20px", fontSize: "16px" }}
              onClick={handleShow}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="custom-modal-dialog"
        contentClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply for a Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src="./images/carrers.jpg" alt="Careers" fluid />
          <form onSubmit={handleSubmit}>
            <div className="formbold-input-flex">
              <div>
                <label htmlFor="name" className="formbold-form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your Full name"
                  className="formbold-form-input"
                  value={formData.name}
                  onChange={handleChange}
                />
                {formErrors.name && (
                  <div className="text-danger">{formErrors.name}</div>
                )}
              </div>
            </div>

            <div className="formbold-input-flex">
              <div>
                <label htmlFor="email" className="formbold-form-label">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@email.com"
                  className="formbold-form-input"
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && (
                  <div className="text-danger">{formErrors.email}</div>
                )}
              </div>
            </div>

            <div className="formbold-mb-3 formbold-input-wrapp">
              <label htmlFor="mobile" className="formbold-form-label">
                {" "}
                {/* Changed from 'phone' to 'mobile' */}
                Mobile
              </label>
              <div>
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="Mobile number"
                  className="formbold-form-input"
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {formErrors.mobile && (
                  <div className="text-danger">{formErrors.mobile}</div>
                )}
              </div>
            </div>

            <div className="formbold-mb-3">
              <label htmlFor="job" className="formbold-form-label">
                Applying for Position:
              </label>
              <input
                type="text"
                name="job"
                id="job"
                className="formbold-form-input"
                value={formData.job}
                onChange={handleChange}
              />
              {formErrors.job && (
                <div className="text-danger">{formErrors.job}</div>
              )}
            </div>

            <div className="formbold-form-file-flex">
              <label htmlFor="resume" className="formbold-form-label">
                Upload Resume
              </label>
              <input
                type="file"
                name="resume"
                id="resume"
                className="formbold-form-file"
                onChange={handleChange}
              />
              {formErrors.resume && (
                <div className="text-danger">{formErrors.resume}</div>
              )}
            </div>

            <Button type="submit" variant="success" className="formbold-btn">
              Apply Now
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div>
  );
};

export default Careers;
