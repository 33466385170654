import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "../../maincomponents/Footer";
import Header from "../../maincomponents/Header";
import Filter from "./Filter";
import Spinner from "./Spinner";

function BrandIcon() {
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBrandData();
  });
  const fetchBrandData = async () => {
    try {
      const response = await axios.get(
        `https://saaskin.com/api/api/brand-icon/`
      );
      setBrandData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product type data:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {/* {loading && <Spinner />} */}
      <Header />
      <Container>
        <Row className="mb-5">
          <Col>
            <Row>
              {brandData.map((brand) => (
                <Col key={brand.id} xs={12} md={4} lg={2}>
                  <Link to={`/BrandIconProducts/${brand.id}`}>
                    <div
                      className="card mt-3"
                      style={{
                        padding: "10px",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <img
                        className="card-img-top"
                        src={brand.brand_image}
                        alt="Card image cap"
                        style={{ height: "160px", objectFit: "contain" }}
                      />
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default BrandIcon;
