import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import axios from "axios";
import SafeHtmlComponent from "./SafeHtmlComponent";

function Filter({ onFilterChange }) {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");

  useEffect(() => {
    fetchCategoryFilterOptions();
  }, []);

  const fetchCategoryFilterOptions = async () => {
    try {
      const response = await axios.get(
        "https://saaskin.com/api/api/categorylist/"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubCategoryFilterOptions = async (categoryId) => {
    try {
      const response = await axios.get(
        `https://saaskin.com/api/api/subcategory/${categoryId}`
      );
      setSubCategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setSubCategories([]);
    }
  };

  const fetchProductTypeFilterOptions = async (subcategoryId) => {
    try {
      const response = await axios.get(
        `https://saaskin.com/api/api/product_type/${subcategoryId}`
      );
      setProductTypes(response.data);
    } catch (error) {
      console.error("Error fetching product types:", error);
      setProductTypes([]);
    }
  };

  const fetchBrandFilterOptions = async (productTypeId) => {
    try {
      const response = await axios.get(
        `https://saaskin.com/api/api/brand/${productTypeId}`
      );
      setBrands(response.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
      setBrands([]);
    }
  };

  const handleCategoryChange = async (e) => {
    const categoryId = e.target.value;
    const categoryName = e.target.options[e.target.selectedIndex].text;
    setSelectedCategory(categoryId);
    setSelectedSubCategory("");
    setSelectedProductType("");
    setSelectedBrand("");
    if (categoryId) {
      await fetchSubCategoryFilterOptions(categoryId);
      onFilterChange("category", categoryId, categoryName);
    }
  };

  const handleSubCategoryChange = async (e) => {
    const subcategoryId = e.target.value;
    const subcategoryName = e.target.options[e.target.selectedIndex].text;
    setSelectedSubCategory(subcategoryId);
    setSelectedProductType("");
    setSelectedBrand("");
    if (subcategoryId) {
      await fetchProductTypeFilterOptions(subcategoryId);
      onFilterChange("subcategory", subcategoryId, subcategoryName);
    }
  };

  const handleProductTypeChange = async (e) => {
    const productTypeId = e.target.value;
    const productTypeName = e.target.options[e.target.selectedIndex].text;
    setSelectedProductType(productTypeId);
    setSelectedBrand("");
    if (productTypeId) {
      await fetchBrandFilterOptions(productTypeId);
      onFilterChange("productType", productTypeId, productTypeName);
    }
  };

  const handleBrandChange = async (e) => {
    const brandId = e.target.value;
    const brandName = e.target.options[e.target.selectedIndex].text;
    setSelectedBrand(brandId);
    if (brandId && selectedProductType) {
      onFilterChange("brand", brandId, brandName);
    }
  };

  return (
    <Col md={3}>
      <div className="product-filter mt-4 p-4 shadow">
        <h5 className="mb-4">
          <b>PRODUCT FILTER</b>
        </h5>
        <Form.Group>
          <Form.Label>
            <b>Category</b>
          </Form.Label>
          <Form.Control
            as="select"
            onChange={handleCategoryChange}
            value={selectedCategory}
          >
            <option value="">Please select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                <SafeHtmlComponent htmlContent={category.name} />
              </option>
            ))}
          </Form.Control>

          <Form.Label>
            <b>SubCategory</b>
          </Form.Label>
          <Form.Control
            as="select"
            onChange={handleSubCategoryChange}
            value={selectedSubCategory}
          >
            <option value="">Please select Subcategory</option>
            {subCategories.map((subcategory) => (
              <option key={subcategory.id} value={subcategory.id}>
                <SafeHtmlComponent htmlContent={subcategory.name} />
              </option>
            ))}
          </Form.Control>

          <Form.Label>
            <b>Product Types</b>
          </Form.Label>
          <Form.Control
            as="select"
            onChange={handleProductTypeChange}
            value={selectedProductType}
          >
            <option value="">Please select Product Types</option>
            {productTypes.map((productType) => (
              <option key={productType.id} value={productType.id}>
                <SafeHtmlComponent htmlContent={productType.name} />
              </option>
            ))}
          </Form.Control>

          <Form.Label>
            <b>Brands</b>
          </Form.Label>
          <Form.Control
            as="select"
            onChange={handleBrandChange}
            value={selectedBrand}
          >
            <option value="">Please select Brands</option>
            {brands.map((brand) => (
              <option key={brand.id} value={brand.id}>
                <SafeHtmlComponent htmlContent={brand.name} />
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
    </Col>
  );
}

export default Filter;
