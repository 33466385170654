import axios from "axios";

const BASE_URL = "https://saaskin.com/api/";

const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const Search = async (searchQuery) => {
  try {
    const response = await ApiService.get(
      `api/productsearch?search=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error searching Data:", error);
    throw error;
  }
};

export const registerUser = async (registerData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}auth/register/`,
      registerData
    );
    return response.data;
  } catch (error) {
    console.error("Error during user registration", error);
    throw error;
  }
};
export const submitCareerApplication = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}auth/career-form/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure this is set when sending FormData
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error submitting career application:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const LoginUser = async (loginData) => {
  try {
    const response = await axios.post(`${BASE_URL}auth/login/`, loginData);
    return response.data;
  } catch (error) {
    console.error("Error during user registration", error);
    throw error;
  }
};

export const OtpVerify = async (otpData) => {
  try {
    const response = await axios.post(`${BASE_URL}auth/verify-email/`, otpData);
    return response.data;
  } catch (error) {
    console.error("Error during user registration", error);
    throw error;
  }
};

export const ForgetPassword = async (forgetPasswordData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}auth/password-reset/`,
      forgetPasswordData
    );
    return response.data;
  } catch (error) {
    console.error("Error during password change", error);
    throw error;
  }
};

export const PasswordResetConfirm = async (uid, token, passwordResetData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}auth/password-reset-confirm/${uid}/${token}/`,
      passwordResetData
    );
    return response.data;
  } catch (error) {
    console.error("Error during password change", error);
    throw error;
  }
};

export const Contact = async (contactData) => {
  try {
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from localStorage
    const response = await axios.post(`${BASE_URL}auth/contact/`, contactData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during Form Submission", error);
    throw error;
  }
};

export const ContactUs = async (contactData) => {
  try {
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from localStorage
    const response = await axios.post(`${BASE_URL}auth/contact/`, contactData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during Form Submission", error);
    throw error;
  }
};

export const fetchCategoryList = async () => {
  try {
    const response = await axios.get(`${BASE_URL}api/categorylist/`);
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
