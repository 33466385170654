// import React from 'react';
// import { useBreadcrumb } from './BreadcrumbContext'; // Adjust the path as necessary
// import { Link } from 'react-router-dom';

// const Breadcrumbs = () => {
//     const { breadcrumbs } = useBreadcrumb();

//     return (
//         <nav aria-label="breadcrumb">
//             <ol className="breadcrumb">
//                 {breadcrumbs.map((crumb, index) => (
//                     <li key={index} className="breadcrumb-item">
//                         <Link to={crumb.path}>{crumb.label}</Link>
//                     </li>
//                 ))}
//             </ol>
//         </nav>
//     );
// };

// export default Breadcrumbs;

import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs = [] }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <li
          key={index}
          className={`breadcrumb-item${
            index === breadcrumbs.length - 1 ? " active" : ""
          }`}
          aria-current={index === breadcrumbs.length - 1 ? "page" : undefined}
        >
          {index === breadcrumbs.length - 1 ? (
            breadcrumb.name
          ) : (
            <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

export default Breadcrumbs;
