import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AxiosInstance from '../utils/AxiosInstance';
import Header from './Header';
import Footer from './Footer';

export const PasswordReset = () => {
    const navigate = useNavigate()
    const { uid, token } = useParams()
    const [newpasswords, setNewPassword] = useState({
        password: "",
        confirm_password: "",
    })
    const { password, confirm_password } = newpasswords

    const handleChange = (e) => {
        setNewPassword({ ...newpasswords, [e.target.name]: e.target.value })
    }

    const data = {
        "password": password,
        "confirm_password": confirm_password,
        "uidb64": uid,
        "token": token,
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (data) {
            const res = await AxiosInstance.patch('set-new-password/', data)
            const response = res.data
            if (res.status === 200) {
                navigate('/')
                toast.success(response.message)
            }
            console.log(response)
        }

    }

    return (
        <div>
            <Header />
            <div className='container mb-5 mt-5'>
            <div className='form-container'>
                <div className='wrapper' style={{ width: "100%" }}>
                <h3 className="forget-pass" >Enter your New Password</h3>
                    <div className='container-fluid'>
                    <form action="" onSubmit={handleSubmit}>
                    <div class="mb-3">
                        <div className='form-group'>
                            <label class="form-label">New Password:</label>
                            <input type="text"
                                 class="form-control"
                                name="password"
                                value={password}
                                onChange={handleChange}
                            />
                        </div>
                        </div>
                        <div class="mb-3">
                        <div className='form-group'>
                            <label  class="form-label">Confirm Password</label>
                            <input type="text"
                                 class="form-control"
                                name="confirm_password"
                                value={confirm_password}
                                onChange={handleChange}
                            />
                        </div>
                        </div>
                        <button type='submit' className="login-btn">Submit</button>
                    </form>
                    </div>
                </div>
            </div>
            </div>
            <Footer/>
        </div>
    );
};
