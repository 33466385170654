import React, { useState } from "react";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import { Form, Button, Row, Col } from "react-bootstrap";

import { FaLocationDot } from "react-icons/fa6";
import { FaMobileAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaRegCalendarDays } from "react-icons/fa6";
import { ContactUs } from "../../utils/ApiService";
import swal from "sweetalert";

import ReactFlagsSelect from "react-flags-select";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState("");

  const handleContact = async (event) => {
    event.preventDefault();
    const contactData = {
      name: name,
      message: message,
      country: selected,
      contact: contact,
      email: email,
    };

    try {
      const response = await ContactUs(contactData);
      swal(
        "Good job!",
        "Thank you for contact us.we will connect to you shortly!!",
        "success"
      );
      setName("");
      setMessage("");
      setSelected("");
      setContact("");
      setEmail("");
    } catch (error) {
      swal("Oops!", "Please Login and try again.", "error");
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <Row className="justify-content-center" style={{ marginTop: "20px" }}>
          <Col xs={12} md={6} lg={3}>
            <div className="text-center">
              <FaLocationDot style={{ fontSize: "20px" }} />
              <h5>Address</h5>
              <p
                style={{
                  fontFamily: "poppins,sans-serif",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                275 /184, First Floor , Office No: 2 , Golden Enclave Periyar
                EVR Salai, Poonamallee High Rd, Kilpauk, Chennai, India
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <div className="text-center">
              <FaMobileAlt style={{ fontSize: "20px" }} />
              <h5>Phone Number</h5>
              <a href="tel:9940116677" style={{ textDecoration: "none" }}>
                <span>+91-9940116677</span>
              </a>
              <br></br>
              <a href="tel:9840819191" style={{ textDecoration: "none" }}>
                <span>+91-9840819191</span>
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <div className="text-center">
              <MdEmail style={{ fontSize: "20px" }} />
              <h5>E-mail Address</h5>
              <a
                href="mailto:info@saaskin.com"
                style={{ textDecoration: "none" }}
              >
                <span>info@saaskin.com</span>
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <div className="text-center">
              <FaRegCalendarDays style={{ fontSize: "20px" }} />
              <h5>Working Days</h5>
              <p>Mon-sat / 9.30AM - 6.30PM (IST)</p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "10px" }}>
          <Col xs={12} md={6}>
            <Form onSubmit={handleContact} className="mt-4">
              <Form.Group controlId="name" className="mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  required
                />
              </Form.Group>
              <Form.Group controlId="email" className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="country" className="mb-3">
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  searchable
                  searchPlaceholder="Search countries"
                />
              </Form.Group>
              <Form.Group controlId="mobile" className="mb-3">
                <Form.Control
                  type="tel"
                  name="mobile"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  placeholder="Mobile Number"
                  required
                />
              </Form.Group>
              <Form.Group controlId="message" className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter Your Message"
                  required
                />
              </Form.Group>
              <center>
                <Button
                  className="px-5"
                  variant="primary"
                  type="submit"
                  style={{ backgroundColor: "#008000" }}
                >
                  Submit
                </Button>
              </center>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <div className="text-center mt-4">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.44248084253!2d80.237902!3d13.0763166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525e7c21d5ee75%3A0xcb68f431b4519298!2sSaaskin%20Corporation%20Private%20Limited!5e0!3m2!1sen!2sin!4v1682870883732!5m2!1sen!2sin"
                width="100%"
                height="400px"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
