// import axios from "axios";
// import { jwtDecode } from "jwt-decode"; // Corrected import
// import dayjs from "dayjs";

// let accessToken = localStorage.getItem("token")
//   ? JSON.parse(localStorage.getItem("token"))
//   : "";
// let refreshToken = localStorage.getItem("refresh_token")
//   ? JSON.parse(localStorage.getItem("refresh_token"))
//   : "";

// console.log("access: ", accessToken);
// const baseURL = "https://saaskin.com/api/";

// const AxiosInstance = axios.create({
//   baseURL: baseURL,
//   "Content-type": "application/json",
//   headers: {
//     Authorization: localStorage.getItem("token") ? `Bearer ${accessToken}` : "",
//   },
// });

// AxiosInstance.interceptors.request.use(async (req) => {
//   if (accessToken) {
//     req.headers.Authorization = localStorage.getItem("token")
//       ? `Bearer ${accessToken}`
//       : "";
//     const user = jwtDecode(accessToken);
//     const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
//     if (!isExpired) return req;

//     const resp = await axios.post(`${baseURL}auth/token/refresh/`, {
//       refresh: refreshToken,
//     });

//     console.log("new_accesstoken: ", resp.data.access);
//     localStorage.setItem("token", JSON.stringify(resp.data.access));
//     req.headers.Authorization = `Bearer ${resp.data.access}`;
//     return req;
//   } else {
//     req.headers.Authorization = localStorage.getItem("token")
//       ? `Bearer ${JSON.parse(localStorage.getItem("token"))}`
//       : " ";
//     return req;
//   }
// });

// export default AxiosInstance;

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

const baseURL = "https://saaskin.com/api/";

const AxiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token")
      ? `Bearer ${JSON.parse(localStorage.getItem("token"))}`
      : "",
  },
});

AxiosInstance.interceptors.request.use(
  async (req) => {
    let accessToken = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : "";
    let refreshToken = localStorage.getItem("refresh_token")
      ? JSON.parse(localStorage.getItem("refresh_token"))
      : "";

    if (accessToken) {
      const user = jwtDecode(accessToken);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

      if (isExpired) {
        try {
          const resp = await axios.post(`${baseURL}auth/token/refresh/`, {
            refresh: refreshToken,
          });

          console.log("new_accesstoken: ", resp.data.access);
          localStorage.setItem("token", JSON.stringify(resp.data.access));
          req.headers.Authorization = `Bearer ${resp.data.access}`;
        } catch (error) {
          console.error("Error refreshing token:", error);
          // Handle token refresh error (e.g., redirect to login)
        }
      } else {
        req.headers.Authorization = `Bearer ${accessToken}`;
      }
    } else {
      req.headers.Authorization = "";
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default AxiosInstance;
