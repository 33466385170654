import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../maincomponents/Footer";
import Header from "../../maincomponents/Header";
import Spinner from 'react-bootstrap/Spinner';
import SafeHtmlComponent from "./SafeHtmlComponent";

const SearchComponent = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const searchResults = location.state?.searchResults || [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://saaskin.com/api/api/categorylist/"
        );
        setData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Spinner animation="border" role="status">
          </Spinner>
        </div>
        <Footer />
      </>
    );
  }

  if (!searchResults || searchResults.length === 0) {
    return (
      <>
        <Header />
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <div>No search results found.</div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          {searchResults.map((result) => (
            <Col key={result.id} xs={12} md={5} lg={3} style={{ textDecoration: "none" }}>
              <Link to={`/product-detail/${result.id}/${result.slug}`} style={{ textDecoration: "none" }} >
                <div className="card mt-3 mb-3" style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", height: "310px" }}>
                  <img
                    className="card-img-top"
                    src={result.image}
                    alt="Product"
                    style={{ height: "220px", objectFit: "contain" }}
                  />
                  <div className="card-body no-underline" style={{ textDecoration: "none" }}>
                    <p className="card-text no-underline" style={{ textAlign: "center", textDecoration: "none", fontWeight: "600" }}>
                      <SafeHtmlComponent htmlContent={result.name} />
                    </p>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default SearchComponent;
