import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go"; // Import eye icons
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import swal from "sweetalert";

const Forgetpassword = () => {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    setPasswordValid(password.length > 8);
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordValid && passwordsMatch) {
      try {
        const response = await axios.post(
          `https://saaskin.com/api/auth/password-reset-confirm/${uidb64}/${token}/`,
          {
            password,
            confirm_password: confirmPassword,
          }
        );

        console.log("Password reset successful:", response.data);
        swal(
          "Success!",
          "Password reset successful! Please log in.",
          "success"
        );
        navigate("/");
      } catch (error) {
        console.error("Error resetting password:", error);
        swal("Error", "Error resetting password. Please try again.", "error");
        setError(
          "An error occurred while resetting the password. Please try again."
        );
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="Forgetpassword">
        <form onSubmit={handleSubmit}>
          <h5 style={{ textAlign: "center" }}>Forget Password</h5>
          <p>
            <label htmlFor="password">Password</label>
            <div style={{ position: "relative" }}>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword ? (
                <GoEyeClosed
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <GoEye
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            {!passwordValid && (
              <span>Enter a password longer than 8 characters</span>
            )}
          </p>
          <p>
            <label htmlFor="confirm_password">Confirm Password</label>
            <div style={{ position: "relative" }}>
              <input
                id="confirm_password"
                name="confirm_password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showConfirmPassword ? (
                <GoEyeClosed
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <GoEye
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            {!passwordsMatch && <span>Passwords do not match</span>}
          </p>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <p>
            <input
              id="submit"
              type="submit"
              value="Submit"
              disabled={!passwordValid || !passwordsMatch}
            />
          </p>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Forgetpassword;
