// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams, useNavigate, Link } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";
// import Footer from "../../maincomponents/Footer";
// import Header from "../../maincomponents/Header";
// import Filter from "./Filter";
// import Spinner from "./Spinner";
// import SafeHtmlComponent from "./SafeHtmlComponent";

// const Brand = () => {
//   const navigate = useNavigate();
//   const { product_typeId } = useParams();
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [currentView, setCurrentView] = useState("productType");
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedSubCategory, setSelectedSubCategory] = useState(null);
//   const [selectedProductType, setSelectedProductType] = useState(null);
//   const [selectedBrand, setSelectedBrand] = useState(null);
//   const [brandProducts, setBrandProducts] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     fetchBrandData();
//   }, [product_typeId]);

//   const fetchBrandData = async () => {
//     try {
//       const response = await axios.get(
//         `https://saaskin.com/api/api/brand/${product_typeId}`
//       );
//       setData(response.data);
//       setFilteredData(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching product type data:", error);
//       setLoading(false);
//     }
//   };

//   const handleFilterChange = async (type, id, name) => {
//     try {
//       let response;
//       switch (type) {
//         case "category":
//           setSelectedCategory(id);
//           setCurrentView("subcategory");
//           response = await axios.get(
//             `https://saaskin.com/api/api/subcategory/${id}`
//           );
//           setFilteredData(response.data);
//           break;
//         case "subcategory":
//           setSelectedSubCategory(id);
//           setCurrentView("productType");
//           response = await axios.get(
//             `https://saaskin.com/api/api/product_type/${id}`
//           );
//           setFilteredData(response.data);
//           break;
//         case "productType":
//           setSelectedProductType(id);
//           setCurrentView("brand");
//           response = await axios.get(`https://saaskin.com/api/api/brand/${id}`);
//           setFilteredData(response.data);
//           break;
//         case "brand":
//           setSelectedBrand(id);
//           setCurrentView("brandProducts");
//           response = await axios.get(
//             `https://saaskin.com/api/api/brand_products/${id}/${selectedProductType}`
//           );
//           setBrandProducts(response.data);
//           break;
//         default:
//           break;
//       }
//     } catch (error) {
//       console.error(`Error fetching data for ${type}:`, error);
//     }
//   };

//   const handleProductClick = (productId, slug) => {
//     navigate(`/product-detail/${productId}/${slug}/`);
//   };

//   return (
//     <>
//       {loading && <Spinner />}
//       <Header />
//       <Container>
//         <Row className="mb-5">
//           <Filter onFilterChange={handleFilterChange} />
//           <Col>
//             <Row>
//               {currentView === "category" &&
//                 filteredData.map((category) => (
//                   <Col key={category.id} xs={12} md={4} lg={3}>
//                     <CategoryCard
//                       category={category}
//                       onClick={() =>
//                         handleFilterChange(
//                           "category",
//                           category.id,
//                           category.name
//                         )
//                       }
//                     />
//                   </Col>
//                 ))}
//               {currentView === "subcategory" &&
//                 filteredData.map((subcategory) => (
//                   <Col key={subcategory.id} xs={12} md={4} lg={4}>
//                     <SubCategoryCard
//                       subcategory={subcategory}
//                       onClick={() =>
//                         handleFilterChange(
//                           "subcategory",
//                           subcategory.id,
//                           subcategory.name
//                         )
//                       }
//                     />
//                   </Col>
//                 ))}
//               {currentView === "productType" &&
//                 filteredData.map((productType) => (
//                   <Col key={productType.id} xs={12} md={4} lg={3}>
//                     <ProductTypeCard
//                       productType={productType}
//                       product_typeId={product_typeId}
//                       onClick={() =>
//                         handleFilterChange(
//                           "productType",
//                           productType.id,
//                           productType.name
//                         )
//                       }
//                     />
//                   </Col>
//                 ))}
//               {currentView === "brand" &&
//                 filteredData.map((brand) => (
//                   <Col key={brand.id} xs={12} md={4} lg={3}>
//                     <BrandCard
//                       brand={brand}
//                       product_typeId={product_typeId}
//                       onClick={() =>
//                         handleFilterChange("brand", brand.id, brand.name)
//                       }
//                     />
//                   </Col>
//                 ))}
//               {currentView === "brandProducts" &&
//                 brandProducts.map((product) => (
//                   <Col key={product.id} xs={12} md={4} lg={3}>
//                     <BrandProductCard
//                       product={product}
//                       onClick={() =>
//                         handleProductClick(product.id, product.slug)
//                       }
//                     />
//                   </Col>
//                 ))}
//             </Row>
//           </Col>
//         </Row>
//       </Container>
//       <Footer />
//     </>
//   );
// };

// const CategoryCard = ({ category }) => (
//   <Link to={`/subcategory/${category.id}`} style={{ textDecoration: "none" }}>
//     <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
//       <img
//         className="card-img-top"
//         src={category.product_image}
//         alt="Card image cap"
//         style={{ height: "220px", objectFit: "contain" }}
//       />
//       <div className="card-body">
//         <p className="card-text" style={{ textAlign: "center" }}>
//           <SafeHtmlComponent htmlContent={category.name} />
//         </p>
//       </div>
//     </div>
//   </Link>
// );

// const SubCategoryCard = ({ subcategory }) => (
//   <Link
//     to={`/product-type/${subcategory.id}`}
//     style={{ textDecoration: "none" }}
//   >
//     <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
//       <img
//         className="card-img-top"
//         src={subcategory.product_image}
//         alt="Card image cap"
//         style={{ height: "220px", objectFit: "contain" }}
//       />
//       <div className="card-body">
//         <p className="card-text" style={{ textAlign: "center" }}>
//           <SafeHtmlComponent htmlContent={subcategory.name} />
//         </p>
//       </div>
//     </div>
//   </Link>
// );

// const ProductTypeCard = ({ productType, product_typeId }) => (
//   <Link
//     to={`/brand-product/${productType.id}/${product_typeId}`}
//     style={{ textDecoration: "none" }}
//   >
//     <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
//       <img
//         className="card-img-top"
//         src={productType.product_image}
//         alt="Card image cap"
//         style={{ height: "220px", objectFit: "contain" }}
//       />
//       <div className="card-body">
//         <p className="card-text" style={{ textAlign: "center" }}>
//           <SafeHtmlComponent htmlContent={productType.name} />
//         </p>
//       </div>
//     </div>
//   </Link>
// );

// const BrandCard = ({ brand, product_typeId }) => (
//   <Link
//     to={`/brand-product/${brand.id}/${product_typeId}`}
//     style={{ textDecoration: "none" }}
//   >
//     <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
//       <img
//         className="card-img-top"
//         src={brand.product_image}
//         alt="Card image cap"
//         style={{ height: "220px", objectFit: "contain" }}
//       />
//       <div className="card-body">
//         <p className="card-text" style={{ textAlign: "center" }}>
//           <SafeHtmlComponent htmlContent={brand.name} />
//         </p>
//       </div>
//     </div>
//   </Link>
// );

// const BrandProductCard = ({ product }) => (
//   <Link
//     to={`/product-detail/${product.id}/${product.slug}/`}
//     style={{ textDecoration: "none" }}
//   >
//     <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
//       <img
//         className="card-img-top"
//         src={product.image}
//         alt="Card image cap"
//         style={{ height: "220px", objectFit: "contain" }}
//       />
//       <div className="card-body">
//         <p className="card-text" style={{ textAlign: "center" }}>
//           <SafeHtmlComponent htmlContent={product.name} />
//         </p>
//       </div>
//     </div>
//   </Link>
// );

// export default Brand;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../maincomponents/Footer";
import Header from "../../maincomponents/Header";
import Filter from "./Filter";
import Spinner from "./Spinner";
import SafeHtmlComponent from "./SafeHtmlComponent";
import { useBreadcrumb } from "./BreadcrumbContext";
import Breadcrumbs from "./Breadcrumbs";

const Brand = () => {
  const navigate = useNavigate();
  const { product_typeId } = useParams();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentView, setCurrentView] = useState("productType");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandProducts, setBrandProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const { updateBreadcrumbs } = useBreadcrumb(); // Use breadcrumb context
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  // useEffect(() => {
  //   fetchBrandData();
  // }, [product_typeId]);

  // const fetchBrandData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://saaskin.com/api/api/brand/${product_typeId}`
  //     );
  //     setData(response.data);
  //     setFilteredData(response.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching brand data:", error);
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        const response = await axios.get(
          `https://saaskin.com/api/api/brand/${product_typeId}`
        );
        setData(response.data);
        setFilteredData(response.data);
        setLoading(false);

        if (response.data.length > 0) {
          const category = response.data[0].category;
          const subcategory = response.data[0].subcategory;
          const product_type = response.data[0].product_type;
          const initialBreadcrumbs = [
            { name: "Home", path: "/" },
            { name: category.name, path: `/category` },
            { name: subcategory.name, path: `/subcategory/${category.id}` },
            {
              name: product_type.name,
              path: `/product-type/${subcategory.id}`,
            },
            { name: "", path: `/Brand/${subcategory.id}` },
          ];
          updateBreadcrumbs(initialBreadcrumbs);
          setBreadcrumbs(initialBreadcrumbs);
        }
      } catch (error) {
        console.error("Error fetching product type data:", error);
        setLoading(false);
      }
    };
    fetchBrandData();
  }, [product_typeId]);

  const handleFilterChange = async (type, id, name) => {
    try {
      let response;
      switch (type) {
        case "category":
          setSelectedCategory(id);
          setCurrentView("subcategory");
          response = await axios.get(
            `https://saaskin.com/api/api/subcategory/${id}`
          );
          setFilteredData(response.data);
          updateBreadcrumbs([
            { name: "Home", path: "/" },
            // { name: "Category", path: `/category/${id}` },
            { name: name, path: `/subcategory/${id}` },
          ]);
          setBreadcrumbs([
            { name: "Home", path: "/" },
            // { name: "Category", path: `/category/${id}` },
            { name: name, path: `/subcategory/${id}` },
          ]);
          break;
        case "subcategory":
          setSelectedSubCategory(id);
          setCurrentView("productType");
          response = await axios.get(
            `https://saaskin.com/api/api/product_type/${id}`
          );
          setFilteredData(response.data);
          const updatedSubcategoryBreadcrumbs = [
            ...breadcrumbs,
            { name, path: `/product-type/${id}` },
          ];
          updateBreadcrumbs(updatedSubcategoryBreadcrumbs);
          setBreadcrumbs(updatedSubcategoryBreadcrumbs);
          break;
        case "productType":
          setSelectedProductType(id);
          setCurrentView("brand");
          response = await axios.get(`https://saaskin.com/api/api/brand/${id}`);
          setFilteredData(response.data);
          const updatedProductTypeBreadcrumbs = [
            ...breadcrumbs,
            { name, path: `/brand/${id}` },
          ];
          updateBreadcrumbs(updatedProductTypeBreadcrumbs);
          setBreadcrumbs(updatedProductTypeBreadcrumbs);
          break;
        case "brand":
          setSelectedBrand(id);
          setCurrentView("brandProducts");
          response = await axios.get(
            `https://saaskin.com/api/api/brand_products/${id}/${selectedProductType}`
          );
          setBrandProducts(response.data);
          const updatedBrandBreadcrumbs = [
            ...breadcrumbs,
            {
              name,
              path: `/brand-product/${id}/${selectedProductType}`,
            },
          ];
          updateBreadcrumbs(updatedBrandBreadcrumbs);
          setBreadcrumbs(updatedBrandBreadcrumbs);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error fetching data for ${type}:`, error);
    }
  };

  const handleProductClick = (productId, slug) => {
    navigate(`/product-detail/${productId}/${slug}/`);
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {/* {loading && <Spinner />} */}
      <Header />
      <Container>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row className="mb-5">
          <Filter onFilterChange={handleFilterChange} />
          <Col>
            <Row>
              {currentView === "category" &&
                filteredData.map((category) => (
                  <Col key={category.id} xs={12} md={4} lg={3}>
                    <CategoryCard
                      category={category}
                      onClick={() =>
                        handleFilterChange(
                          "category",
                          category.id,
                          category.name
                        )
                      }
                    />
                  </Col>
                ))}
              {currentView === "subcategory" &&
                filteredData.map((subcategory) => (
                  <Col key={subcategory.id} xs={12} md={4} lg={4}>
                    <SubCategoryCard
                      subcategory={subcategory}
                      onClick={() =>
                        handleFilterChange(
                          "subcategory",
                          subcategory.id,
                          subcategory.name
                        )
                      }
                    />
                  </Col>
                ))}
              {currentView === "productType" &&
                filteredData.map((productType) => (
                  <Col key={productType.id} xs={12} md={4} lg={3}>
                    <ProductTypeCard
                      productType={productType}
                      product_typeId={product_typeId}
                      onClick={() =>
                        handleFilterChange(
                          "productType",
                          productType.id,
                          productType.name
                        )
                      }
                    />
                  </Col>
                ))}
              {currentView === "brand" &&
                filteredData.map((brand) => (
                  <Col key={brand.id} xs={12} md={4} lg={3}>
                    <BrandCard
                      brand={brand}
                      product_typeId={product_typeId}
                      onClick={() =>
                        handleFilterChange("brand", brand.id, brand.name)
                      }
                    />
                  </Col>
                ))}
              {currentView === "brandProducts" &&
                brandProducts.map((product) => (
                  <Col key={product.id} xs={12} md={4} lg={3}>
                    <BrandProductCard
                      product={product}
                      onClick={() =>
                        handleProductClick(product.id, product.slug)
                      }
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

const CategoryCard = ({ category }) => (
  <Link to={`/subcategory/${category.id}`} style={{ textDecoration: "none" }}>
    <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
      <img
        className="card-img-top"
        src={category.product_image}
        alt="Card image cap"
        style={{ height: "220px", objectFit: "contain" }}
      />
      <div className="card-body">
        <p
          className="card-text"
          style={{ textAlign: "center", height: "60px" }}
        >
          <SafeHtmlComponent htmlContent={category.name} />
        </p>
      </div>
    </div>
  </Link>
);

const SubCategoryCard = ({ subcategory }) => (
  <Link
    to={`/product-type/${subcategory.id}`}
    style={{ textDecoration: "none" }}
  >
    <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
      <img
        className="card-img-top"
        src={subcategory.product_image}
        alt="Card image cap"
        style={{ height: "220px", objectFit: "contain" }}
      />
      <div className="card-body">
        <p
          className="card-text"
          style={{ textAlign: "center", height: "60px" }}
        >
          <SafeHtmlComponent htmlContent={subcategory.name} />
        </p>
      </div>
    </div>
  </Link>
);

const ProductTypeCard = ({ productType, product_typeId }) => (
  <Link
    to={`/brand-product/${productType.id}/${product_typeId}`}
    style={{ textDecoration: "none" }}
  >
    <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
      <img
        className="card-img-top"
        src={productType.product_image}
        alt="Card image cap"
        style={{ height: "220px", objectFit: "contain" }}
      />
      <div className="card-body">
        <p
          className="card-text"
          style={{ textAlign: "center", height: "60px" }}
        >
          <SafeHtmlComponent htmlContent={productType.name} />
        </p>
      </div>
    </div>
  </Link>
);

const BrandCard = ({ brand, product_typeId }) => (
  <Link
    to={`/brand-product/${brand.id}/${product_typeId}`}
    style={{ textDecoration: "none" }}
  >
    <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
      <img
        className="card-img-top"
        src={brand.product_image}
        alt="Card image cap"
        style={{ height: "220px", objectFit: "contain" }}
      />
      <div className="card-body">
        <p
          className="card-text"
          style={{ textAlign: "center", height: "60px" }}
        >
          <SafeHtmlComponent htmlContent={brand.name} />
        </p>
      </div>
    </div>
  </Link>
);

const BrandProductCard = ({ product }) => (
  <Link
    to={`/product-detail/${product.id}/${product.slug}/`}
    style={{ textDecoration: "none" }}
  >
    <div className="card mt-3" style={{ width: "100%", cursor: "pointer" }}>
      <img
        className="card-img-top"
        src={product.image}
        alt="Card image cap"
        style={{ height: "220px", objectFit: "contain" }}
      />
      <div className="card-body">
        <p
          className="card-text"
          style={{ textAlign: "center", height: "60px" }}
        >
          <SafeHtmlComponent htmlContent={product.name} />
        </p>
      </div>
    </div>
  </Link>
);

export default Brand;
