import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate, Link } from "react-router-dom";

const Navthree = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Fetch data from backend on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Function to fetch data from backend using Axios
  const fetchData = async () => {
    try {
      // Make API call to fetch data from backend using Axios
      const response = await axios.get(
        "https://saaskin.com/api/api/categorylist/"
      );
      setData(response.data);
      setFilteredData(response.data); // Ensure filteredData is initialized with data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAllButtonClick = () => {
    navigate('/Category');
  };


  return (
    <div className="container" style={{ display: "flex", overflowX: "scroll" }}>
      <div className="row" style={{ padding: "5px 5px 20px", fontSize: "13px", fontWeight: "400", fontFamily: "poppins,sans-serif" }}>
        <div className="d-flex flex-nowrap overflow-auto" style={{ paddingRight: "5px", gap: "30px" }}>
          <div className="text-center" onClick={handleAllButtonClick}>
            <img
              style={{ width: "60px", height: "60px" }}
              src="/images/menu_icon_001.png" // Corrected image path
              alt="All"
            />
            <span style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>All</span>
          </div>

          {filteredData &&
            filteredData.map((item) => (
              <Link key={item.id} to={`/subcategory/${item.id}`} style={{textDecoration:"none"}}>
                <div className="text-center" style={{ marginRight: "10px" }}>
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{ width: "60px", height: "60px" }}
                  />
                <span style={{ display: "flex", flexDirection: "column", marginTop: "5px", textDecoration: "none" ,color:"black"}}>{item.name}</span>

                </div>
              </Link>
            ))}

          <div className="text-center">
            <Link to="/BrandIcon" style={{textDecoration:"none"}}>
            <img
              style={{ width: "60px", height: "60px" }}
              src="/images/menuicon7.jpeg" // Corrected image path
              alt="Brands"
            />
            <span style={{ display: "flex", flexDirection: "column", marginTop: "5px",color:"black" }}>Brands</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navthree;
