import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import SafeHtmlComponent from "./SafeHtmlComponent";
import Spinner from "./Spinner";
import Footer from "../../maincomponents/Footer";
import Header from "../../maincomponents/Header";

function BrandIconProducts() {
  const { brandId } = useParams(); // Get the brand_id from URL parameters
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProducts();
  }, [brandId]); // Refetch products whenever brand_id changes

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `https://saaskin.com/api/api/brand-icon/products/${brandId}`
      );
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
    }
  };

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(products.length / productsPerPage); i++) {
    pageNumbers.push(i);
  }
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {/* {loading && <Spinner />} */}
      <Header />
      <Container>
        <Row className="mb-5">
          <Col>
            <Row>
              {currentProducts.map((product) => (
                <Col key={product.id} xs={12} md={5} lg={3}>
                  <Link
                    to={`/product-detail/${product.id}/${product.slug}`}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                  >
                    <div
                      className="card mt-3"
                      style={{
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        height: "300px",
                      }}
                    >
                      <img
                        className="card-img-top"
                        src={product.image}
                        alt="Product"
                        style={{ height: "220px", objectFit: "contain" }}
                      />
                      <div className="card-body">
                        <p className="card-text">
                          <SafeHtmlComponent htmlContent={product.name} />
                        </p>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
            <Row className="mt-4">
              <Col>
                <nav>
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <Button
                        onClick={() => paginate(currentPage - 1)}
                        className="page-link"
                        disabled={currentPage === 1}
                      >
                        &laquo;
                      </Button>
                    </li>
                    {pageNumbers.map((number) => (
                      <li key={number} className="page-item">
                        <Button
                          onClick={() => paginate(number)}
                          className="page-link"
                          active={currentPage === number}
                        >
                          {number}
                        </Button>
                      </li>
                    ))}
                    <li className="page-item">
                      <Button
                        onClick={() => paginate(currentPage + 1)}
                        className="page-link"
                        disabled={currentPage === pageNumbers.length}
                      >
                        &raquo;
                      </Button>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default BrandIconProducts;
